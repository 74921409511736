document.addEventListener("DOMContentLoaded", (event) => {
  document.querySelector("form").addEventListener("submit", handleSubmit);
});

const handleSubmit = async (e) => {
  e.preventDefault();
  let contactForm = document.getElementById("contact-form");
  let spinner = document.getElementById("form-spinner");
  let icon = document.getElementById("form-icon-submit");
  let thankMessage = document.getElementById("thank-message");
  let errorMessage = document.getElementById("form-error");

  let formData = new FormData(contactForm);
  if (!formData.get("email")) {
    errorMessage.classList.remove("hidden");
    return;
  }

  try {
    icon.classList.add("hidden");
    errorMessage.classList.add("hidden");
    spinner.classList.remove("hidden");
    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    });
    setTimeout(() => {
      thankMessage.classList.remove("translate-y-full");
      thankMessage.classList.remove("md:translate-x-full");
    }, 500);
  } catch (error) {
    console.error(error);
    errorMessage.classList.remove("hidden");
  } finally {
    setTimeout(() => {
      spinner.classList.add("hidden");
      icon.classList.remove("hidden");
    }, 1000);
  }
};
